import { useEffect, useState } from "react";
import { useVariation } from "@hackler/react-sdk";
import { sessionStorage } from "@/utils/storage";

export const useCustomVariation = (
  experimentKey: number,
  defaultVariation?: string
): string => {
  const [clientVariation, setClientVariation] = useState<string | null>(null);
  const variation = useVariation(experimentKey, defaultVariation || "Z");

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.location.origin.includes("localhost")
    ) {
      const test_variation = sessionStorage.get("local_test_variation");
      if (
        test_variation === "A" ||
        test_variation === "B" ||
        test_variation === "C" ||
        test_variation === "D"
      )
        setClientVariation(String(test_variation));
    }
  }, []);

  return clientVariation || variation;
};
