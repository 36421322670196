import { MAX_WIDTH } from "@/constants/components";
import { useCustomVariation } from "@/hooks/useCustomVariation";
import { Box, Stack } from "@chakra-ui/react";
import {
  getCdnImageSrc,
  Image,
  useResponsive,
  VStack,
  LARGEL_BUTTON_TYPE,
  LargeButton,
  NextLink,
  responsive,
} from "goi_common";
import { ReactNode } from "react";

export default function BenefitSection() {
  const { isBiggerThanLg } = useResponsive();

  const variation = useCustomVariation(32);
  const isVariationA = variation === "A";

  return (
    <VStack width="100%" alignItems="center">
      <VStack
        maxWidth={`${MAX_WIDTH}px`}
        gap={isBiggerThanLg ? "120px" : "40px"}
        css={responsive({
          padding: {
            base: "64px 24px",
            lg: "120px 24px",
          },
        })}
      >
        <BenefitItem
          num={1}
          title={"장례 비용 오를 걱정 없이,\n지금 가격 평생 보장해드려요"}
          subTitle="매년 올라가는 장례 비용."
          description={
            isVariationA ? (
              <>
                지금 가입하면 <b>10년 후에도 지금 가격을 보장해 드려요.</b>{" "}
                <br />
                고이 프라임 상조 하나만으로 든든하게 대비하세요.
              </>
            ) : (
              <>
                지금 가입하면 <b>10년 후에도 지금 가격을 보장해 드려요.</b>{" "}
                <br />
                24년 가입자분들은 이미 20만원 이득을 보셨어요.
              </>
            )
          }
        />
        <BenefitItem
          num={2}
          title={"부담 없는 월 납입금,\n100원이면 충분해요."}
          subTitle="매달 3만원씩 나가는 상조회비."
          description={
            <>
              불합리한 가격 거품을 없앤 고이 프라임 상조에서 매달 100원으로
              부담없이 가입해 보세요. 장례 발생시까지 납부하고,{" "}
              <b>잔액은 장례식 이후 후불로 납부하면 돼요.</b>
            </>
          }
        />
        <BenefitItem
          num={3}
          title={"거품을 없앤 장례,\n가격을 50%로 낮췄어요."}
          subTitle="가격 거품이 걱정되는 장례"
          description={
            <>
              <b>IT 기술과 투명한 서비스 정책으로 거품을 없앴어요.</b> 월
              납입금과 장례 비용 부담을 모두 줄이고, 높은 품질의 장례 서비스를
              제공해요.
            </>
          }
        />
        <VStack width="100%" alignItems="center">
          <NextLink
            href="/service/"
            css={responsive({
              width: { base: "100%", lg: "300px" },
            })}
            event={{
              newGtm: { path: "home", trigger_and_target: "uv_service" },
            }}
          >
            <LargeButton
              buttonType={LARGEL_BUTTON_TYPE.ORANGE}
              className="subtitle_21_b lg_head_28_b"
              css={responsive({
                borderRadius: "10px",
                height: { lg: "86px", base: "66px" },
              })}
            >
              보장 상품 자세히 보기
            </LargeButton>
          </NextLink>
        </VStack>
      </VStack>
    </VStack>
  );
}

function BenefitItem({
  num,
  title,
  subTitle,
  description,
}: {
  num: number;
  title: string;
  subTitle: string;
  description: ReactNode;
}) {
  const variation = useCustomVariation(32);
  const isVariationA = variation === "A";

  return (
    <Stack
      gap="16px"
      justifyContent="space-between"
      css={responsive({ flexDirection: { base: "column", lg: "row" } })}
    >
      <VStack
        justifyContent="center"
        css={responsive({
          width: { base: "100%", lg: "50%" },
        })}
      >
        <Box className="orange650 head_24_b">0{num}</Box>
        <Box
          className="gray900 head_24_b lg_head_42_b"
          css={responsive({
            whiteSpace: "pre-line",
            padding: { base: "16px 0 24px", lg: "16px 0 40px" },
          })}
        >
          {title}
        </Box>
        <Box
          className="brown650 subtitle_14_sb lg_head_24_b"
          css={responsive({
            paddingBottom: { base: "4px", lg: "8px" },
          })}
        >
          {subTitle}
        </Box>
        <Box
          className="gray600 body_14_r lg_head_24_r"
          css={{ wordBreak: "keep-all" }}
        >
          {description}
        </Box>
      </VStack>
      <VStack
        borderRadius="16px"
        css={responsive({
          width: { base: "100%", lg: "50%" },
          height: { base: "100%", lg: "366px" },
        })}
      >
        <Image
          src={getCdnImageSrc(
            `direct/home/benefit-2-${
              num === 1 ? (isVariationA ? num : num + "-b") : num
            }.png`
          )}
          width="100%"
          height="auto"
          alt=""
        />
      </VStack>
    </Stack>
  );
}
